import React, { PureComponent } from 'react';

class VerticalTileGallery extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      remainder: 0,
    };
  }
  componentDidMount() {
    try {
      let remainder = this.props.items.length - 3;
      this.setState({
        remainder: remainder,
      });
    } catch (error) {
      console.log(error);
    }
  }
  showMask(item) {
    try {
      let obj = {
        type: item.type,
        src: item.src,
      };

      return this.state.remainder > 0 ? (
        <div
          className="mask higher"
          onClick={(e) => this.props.onShowBigImg(e, obj)}
        >
          +{this.state.remainder}
        </div>
      ) : (
        ''
      );
    } catch (error) {
      console.log(error);
    }
  }
  showItem(item) {
    try {
      let obj = {
        type: item.type,
        src: item.src,
      };
      return item.type === 'image' ? (
        <img
          src={item.src}
          // alt="pic"
          className="pic"
          alt={item.name}
          onClick={(e) => this.props.onShowBigImg(e, obj)}
        />
      ) : (
        <>
          <div
            className="mask"
            onClick={(e) => this.props.onShowBigImg(e, obj)}
          >
            <img className="img" alt="Play" src="/img/play-light-2-pt.svg" />
          </div>
          <img className="pic" alt={item.name} src={item.videoThumbnailUrl} />
        </>
      );
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    return (
      <>
        {this.props.items.length > 0 ? (
          <div className="gallery vtile">
            <div className="firstTile">
              {this.showItem(this.props.items[0])}
            </div>
            {this.props.items.length > 1 ? (
              <div className="tileRow">
                <div className="secondTile">
                  {this.showItem(this.props.items[1])}
                </div>
                {this.props.items.length > 2 ? (
                  <div className="thirdTile">
                    {this.showMask(this.props.items[2])}
                    {this.showItem(this.props.items[2])}
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}
export default React.memo(VerticalTileGallery);
