import React, { PureComponent } from 'react';

class TileGallery extends PureComponent {
  constructor(props) {
    super(props);
    this.mask = React.createRef();
    this.state = {
      remainder: 0,
      isSingleVideo: false,
    };
  }
  componentDidUpdate() {
    if (this.props.items.length === 1) {
      if (
        this.props.top > 0 &&
        this.props.top < 100 &&
        this.mask.current != null
      ) {
        this.mask.current.click();
      }
    }
  }
  componentDidMount() {
    try {
      let remainder = this.props.items.length - 3;
      this.setState({
        remainder: remainder,
      });
    } catch (error) {
      console.log(error);
    }
  }

  showMask() {
    try {
      return this.state.remainder > 0 ? (
        <div className="mask higher">+{this.state.remainder}</div>
      ) : (
        ''
      );
    } catch (error) {
      console.log(error);
    }
  }

  showItem(item) {
    try {
      return item.type === 'image' ? (
        <img
          src={item.src}
          alt="my pic"
          className="pic"
          onClick={(e) => this.props.onShowBigImg(e, item)}
        />
      ) : (
        <>
          <div
            ref={this.mask}
            className="mask"
            onClick={(e) => this.props.onShowBigImg(e, item)}
          >
            <img
              className="img"
              src="/img/play-light-2-pt.svg"
              alt="Play Video"
            />
          </div>
          <img
            className="pic"
            src={item.videoThumbnailUrl}
            alt="Video Thumbnail"
          />
        </>
      );
    } catch (error) {
      console.log(error);
    }
  }
  triggerShowBig(e, file) {
    try {
      this.props.onShowBigImg(e, { type: file.type, src: file.src });
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    return (
      <>
        {this.props.items.length > 0 ? (
          <div className="gallery tile">
            <div className="firstTile">
              {this.showItem(this.props.items[0])}
            </div>
            {this.props.items.length > 1 ? (
              <div className="tileRow">
                <div className="secondTile">
                  {this.showItem(this.props.items[1])}
                </div>
                {this.props.items.length > 2 ? (
                  <>
                    <div
                      className="thirdTile"
                      onClick={(e) =>
                        this.props.onShowBigImg(e, this.props.items[2])
                      }
                    >
                      {this.showMask()}
                      {this.showItem(this.props.items[2])}
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default React.memo(TileGallery);
