import React, { PureComponent } from 'react';

class SinglePortait extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  triggerSlider(e) {
    try {
      let obj = {
        type: this.props.items[0].type,
        src: this.props.items[0].src,
      };
      this.props.onShowBigImg(e, obj);
    } catch (error) {
      console.log(error);
    }
  }

  showImage(img) {
    try {
      return (
        <>
          <img src={img} alt="pic" onClick={(e) => this.triggerSlider()} />
          <img
            onClick={(e) => this.triggerSlider()}
            src={img}
            className="bgImage"
            alt="bg pic"
          />
        </>
      );
    } catch (error) {
      console.log(error);
    }
  }

  showItem(item) {
    try {
      return item.type === 'image' ? (
        this.showImage(item.src)
      ) : (
        <video width="320" height="240" controls>
          {' '}
          <source src={item.src} type="video/mp4" />
        </video>
      );
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    return (
      <>
        {this.props.items && this.props.items.length === 1 ? (
          <div className="gallery singlePortait">
            <div className="firstTile">
              {this.showItem(this.props.items[0])}
            </div>
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}
export default React.memo(SinglePortait);
